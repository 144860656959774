import { useAppSelector } from "../../../../store/hooks";
import useGanttChartPlacement from "../../../../hooks/useGanttChartPlacement";
import ganttChartService from "../../../../service/ganttChartService";

const CombinedTaskRowBackgroundColor = ({ rowWidth }: { rowWidth: number }) => {
  const { selectedGanttChartTab, ganttChartData } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  // const [placementOnYAxis, setPlacementOnYAxis] = useState<number>();

  //unplannedRowHeight,
  const { individualRowHeight, unplannedRowHeightWrapper } =
    useGanttChartPlacement();

  const getPondooRow = (index: number) => {
    if (selectedGanttChartTab === "Both")
      return (
        <rect
          x={0}
          y={ganttChartService.getPlacementOnYAxisForColoredRow(index)}
          width={rowWidth}
          height={individualRowHeight}
          fill={"var(--col-primary-40)"}
        />
      );
  };

  return (
    <>
      {ganttChartData!.employees.map((_, index) => (
        <>
          <rect
            x={0}
            y={
              ganttChartService.getPlacementOnYaxisForWhiteRow(
                index,
                selectedGanttChartTab,
                individualRowHeight
              ) + unplannedRowHeightWrapper
            }
            width={rowWidth}
            height={individualRowHeight}
            fill={
              selectedGanttChartTab === "Both"
                ? "white"
                : ganttChartService.rowColor(
                    "Pondoo",
                    index,
                    selectedGanttChartTab
                  )
            }
          />
          {getPondooRow(index + 1)}
        </>
      ))}
    </>
  );
};

export default CombinedTaskRowBackgroundColor;
