import { SearchItem } from "../store/search";
import { Employee } from "../types/ganttChart";
import PrefixTrie from "../utils/search/prefixtrie";

const groupBy = <T, R extends string | number | symbol>(
  data: T[],
  grouper: (input: T) => R,
): Map<R, T[]> => {
  const groups = new Map<R, T[]>();

  for (let item of data) {
    const key = grouper(item);
    if (groups.has(key)) {
      groups.get(key)!.push(item);
      continue;
    }
    groups.set(key, [item]);
  }

  return groups;
};

const intersect = <T extends string | number>(arr1: T[], arr2: T[]): T[] => {
  const obj = {} as { [key in T]: boolean };
  for (const item of arr2) {
    obj[item] = true;
  }
  return arr1.filter((x) => obj[x]);
};

class SearchIndex {
  private trie: PrefixTrie = new PrefixTrie(false);

  constructor(data: Employee[]) {
    this.buildIndex(data);
  }

  private buildIndex = (data: Employee[]) => {
    const workTasks = data.flatMap((employee) => employee.tasks.workTasks);
    const workTasksByCitizenId = groupBy(
      workTasks,
      (wt) => wt.client.idServiceClient,
    );
    const citizensById = groupBy(
      workTasks.map((wt) => wt.client),
      (client) => client.idServiceClient,
    );

    const subTasks = workTasks
      .map((wt) => wt.subTasks.map((st) => ({ id: wt.idTask, subTask: st })))
      .flatMap((subTaskWithId) => subTaskWithId);

    workTasksByCitizenId.forEach((workTasks, citizenId) => {
      const citizen: SearchItem = {
        string: citizensById.get(citizenId)![0].description,
        ids: workTasks.map((wt) => wt.idTask),
        itemType: "CITIZEN",
      };
      this.trie.insert(citizen, (c) => c.string);
    });

    // Group subtasks by description
    const taskGroups = groupBy(
      subTasks,
      (subTask) => subTask.subTask.description,
    );
    taskGroups.forEach((subTaskWithIdContainer, taskName) => {
      const subTaskSearchItem: SearchItem = {
        string: taskName,
        ids: subTaskWithIdContainer.map((sti) => sti.id),
        itemType: "TASK",
      };
      this.trie.insert(subTaskSearchItem, (subTask) => subTask.string);
    });
  };

  public suggest = (prefix: string, appliedFilters: SearchItem[] = []) => {
    const preliminaryResults = this.trie
      .searchItemsByPrefix(prefix, (node) => node.data)
      .flatMap((item) => item) as SearchItem[];

    // Filter if necessary
    if (appliedFilters.length === 0) return preliminaryResults;

    const allowedIds = new Set(
      intersect(
        preliminaryResults.flatMap((res) => res.ids),
        appliedFilters.flatMap((filter) => filter.ids),
      ),
    );

    return this.filterSearchResultsByAllowedIds(preliminaryResults, allowedIds);
  };

  private filterSearchResultsByAllowedIds = (
    results: SearchItem[],
    allowedIds: Set<number>,
  ): SearchItem[] => {
    const outgoing = [];
    for (const result of results) {
      for (const id of result.ids) {
        if (allowedIds.has(id)) {
          outgoing.push({ ...result, ids: [id] });
        }
      }
    }
    return outgoing;
  };
}

export default SearchIndex;
