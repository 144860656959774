import { useEffect } from 'react';
import MainWhiteWrapper from '../components/UI/MainWhiteWrapper';
import { useAppSelector } from '../store/hooks';
import labels from '../utils/labels';

export default function KPIPage() {
  const { kpiData } = useAppSelector((state) => state.kpiCollectionSlice);

  useEffect(() => {}, []);
  return (
    <article className="flex flex-col w-full gap-[var(--spacing-sm)]">
      <MainWhiteWrapper>{labels.citizenService}</MainWhiteWrapper>
      <div className="flex gap-[var(--spacing-sm)]">
        <MainWhiteWrapper width="50%">{labels.time}</MainWhiteWrapper>
        <MainWhiteWrapper width="50%">{labels.expenses}</MainWhiteWrapper>
      </div>

      <div className="flex gap-[var(--spacing-sm)] overflow-y-auto">
        <div className="w-[50%]">
          Nexus:
          <br />
          Plan - Kontinuitet: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plan.continuity : ''}
          <br />
          Plan - Totale regler: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plan.totalRules : ''}
          <br />
          Plan - Overholdte regler: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plan.compliedRules : ''}
          <br />
          Plan - Procent overholdte regler: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plan.percentCompliedRules : ''}
          <br />
          Plan - Totale præferencer: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plan.totalPreferences : ''}
          <br />
          Plan - Overholdte præferencer: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plan.compliedPreferences : ''}
          <br />
          Plan - Procent overholdte præferencer: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plan.percentCompliedPreferences : ''}
          <br />
          <br />
          Other - Planlagte medarbejdere: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.otherKpi.plannedEmployees : ''}
          <br />
          Other - Antal medarbejdere: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.otherKpi.totalEmployees : ''}
          <br />
          Other - Uplanlagte kalender opgaver: {kpiData?.nexusDashboard ? kpiData.nexusDashboard.otherKpi.unplannedCalendarEvents : ''}
          <br />
          Other - Total antal kalender opgaver: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.otherKpi.totalCalendarEvents : ''}
          <br />
          Other - Uplanlagte basøg: {kpiData?.nexusDashboard ? kpiData.nexusDashboard.otherKpi.unplannedVisits : ''}
          <br />
          Other - Total antal basøg: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.otherKpi.totalVisits : ''}
          <br />
          Other - Kørte km: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.otherKpi.drivenKilometers : ''}
          <br />
          <br />
          PlannedTime - Procent borgertid: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plannedTime.percentVisitTime : ''}
          <br />
          PlannedTime - Procent kørsel: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plannedTime.percentDriveTime : ''}
          <br />
          PlannedTime - Procent andre kalenderaftaler: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.plannedTime.percentOtherTime : ''}
          <br />
          <br />
          WorkTime - Vagtplan: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.workTime.shiftHours : ''}
          <br />
          WorkTime - Overtid: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.workTime.overtimeHours : ''}
          <br />
          WorkTime - Uplanlagt tid: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.workTime.unplannedHours : ''}
          <br />
          WorkTime - Planlagt tid: {kpiData?.nexusDashboard ? kpiData?.nexusDashboard.workTime.shiftScheduleHours : ''}
        </div>
        <div  className="w-[50%]">
          Pondoo:
          <br />
          Plan - Kontinuitet: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plan.continuity : ''}
          <br />
          Plan - Totale regler: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plan.totalRules : ''}
          <br />
          Plan - Overholdte regler: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plan.compliedRules : ''}
          <br />
          Plan - Procent overholdte regler: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plan.percentCompliedRules : ''}
          <br />
          Plan - Totale præferencer: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plan.totalPreferences : ''}
          <br />
          Plan - Overholdte præferencer: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plan.compliedPreferences : ''}
          <br />
          Plan - Procent overholdte præferencer: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plan.percentCompliedPreferences : ''}
          <br />
          <br />
          Other - Planlagte medarbejdere: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.otherKpi.plannedEmployees : ''}
          <br />
          Other - Antal medarbejdere: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.otherKpi.totalEmployees : ''}
          <br />
          Other - Uplanlagte kalender opgaver: {kpiData?.pondooDashboard ? kpiData.pondooDashboard.otherKpi.unplannedCalendarEvents : ''}
          <br />
          Other - Total antal kalender opgaver: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.otherKpi.totalCalendarEvents : ''}
          <br />
          Other - Uplanlagte basøg: {kpiData?.pondooDashboard ? kpiData.pondooDashboard.otherKpi.unplannedVisits : ''}
          <br />
          Other - Total antal basøg: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.otherKpi.totalVisits : ''}
          <br />
          Other - Kørte km: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.otherKpi.drivenKilometers : ''}
          <br />
          <br />
          PlannedTime - Procent borgertid: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plannedTime.percentVisitTime : ''}
          <br />
          PlannedTime - Procent kørsel: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plannedTime.percentDriveTime : ''}
          <br />
          PlannedTime - Procent andre kalenderaftaler: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.plannedTime.percentOtherTime : ''}
          <br />
          <br />
          WorkTime - Vagtplan: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.workTime.shiftHours : ''}
          <br />
          WorkTime - Overtid: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.workTime.overtimeHours : ''}
          <br />
          WorkTime - Uplanlagt tid: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.workTime.unplannedHours : ''}
          <br />
          WorkTime - Planlagt tid: {kpiData?.pondooDashboard ? kpiData?.pondooDashboard.workTime.shiftScheduleHours : ''}
        </div>
      </div>
    </article>
  );
}
