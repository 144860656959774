import { configureStore, combineReducers } from "@reduxjs/toolkit";
import apiConfigurationstate from "./apiConfigurationstate";
import ganttChartSlice from "./ganttChart/index";
import dragAndDropSlice from "./dragAndDrop/index";
import modalSlice from "./drawer/index";
import timelineSlice from "./timeline/index";
import nexusFiltersSlice from "./nexusFilters";
import kpiCollectionSlice from "./KPICollection";
import searchSlice from "./search";

const store = configureStore({
  reducer: combineReducers({
    apiConfigurationstate,
    ganttChartSlice,
    dragAndDropSlice,
    modalSlice,
    timelineSlice,
    nexusFiltersSlice,
    kpiCollectionSlice,
    searchSlice,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
