// import { useAppSelector } from "../../../store/hooks";
import { Employee } from "../../../types/ganttChart";
import VerticalTextRow from "./VerticalTextRow";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import KpiWrapper from "../KpiWrapper";
import VerticalGanttChartTransportationIcon from "./VerticalGanttChartTransportationIcon";
import ViolationChip from "../../UI/ViolationChip";

const VerticalTextRows = ({
  employees,
  isSticky,
}: {
  employees: Employee[];
  isSticky?: boolean;
}) => {
  // const { selectedGanttChartTab } = useAppSelector(
  //   (state) => state.ganttChartSlice
  // );
  const getContinuityArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) => (
      <KpiWrapper
        value={Math.round(employee.shiftSchedule.kpi.continuity * 10) / 10}
        showColoredChip
        key={index}
      />
    ));
    // todo: is this needed?
    // } else if (selectedGanttChartTab === "Pondoo") {
    //   return employees.map((employee, index) =>
    //     employee.pondooTasks ? (
    //       <KpiWrapper
    //         value={Math.round(employee.pondoShiftSchedule.kpi.continuity*10)/10}
    //         showColoredChip
    //         key={index}
    //       />
    //     ) : (
    //       "-"
    //     )
    //   );
    // }
  };

  const getDistanceArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) => (
      <KpiWrapper
        value={Math.round(
          employee.shiftSchedule.kpi.travelDistanceInMeters / 1000
        )}
        key={index}
      >
        <VerticalGanttChartTransportationIcon
          type={employee.transportationType}
          color="var(--col-black)"
          size={10}
        />
      </KpiWrapper>
    ));
    // todo: is this still needed?
    // } else if (selectedGanttChartTab === "Pondoo") {
    //   return employees.map((employee, index) =>
    //     employee.pondooTasks ? (
    //       <KpiWrapper value={employee.pondoShiftSchedule.kpi.travelDistanceInMeters} key={index} ><VerticalGanttChartTransportationIcon type={employee.transportationType} color="var(--col-black)" size={10}/></KpiWrapper>
    //     ) : (
    //       "-"
    //     )
    //   );
    // }
  };

  const getViolationsArray = () => {
    // if (selectedGanttChartTab === "Nexus") {
    return employees.map((employee, index) =>
      employee.shiftSchedule.kpi.violations &&
      employee.shiftSchedule.kpi.violations.length > 0 ? (
        <KpiWrapper
          value={employee.shiftSchedule.kpi.violations.length}
          key={index}
        >
          <ViolationChip height={14} width={14} />
        </KpiWrapper>
      ) : (
        ""
      )
    );
    // todo: is this needed?
    // } else if (selectedGanttChartTab === "Pondoo") {
    //   return employees.map((employee, index) =>
    //   employee.pondoShiftSchedule.kpi.violations ? (
    //       <KpiWrapper value={employee.pondoShiftSchedule.kpi.violations.length} key={index} ><ViolationChip/></KpiWrapper>
    //     ) : (
    //       "-"
    //     )
    //   );
    // }
  };

  return (
    <section
      className={`${ganttChartStyles.verticalTextRow} ${isSticky ? ganttChartStyles.fold : ganttChartStyles.unfold}`}
    >
      <VerticalTextRow
        title="Kontinuitet"
        array={getContinuityArray()}
      ></VerticalTextRow>
      <VerticalTextRow
        title="Kørsel (km)"
        array={getDistanceArray()}
      ></VerticalTextRow>
      {/* Todo: update component on task with violations */}
      <VerticalTextRow
        title="Regelbrud"
        array={getViolationsArray()}
      ></VerticalTextRow>
    </section>
  );
};

export default VerticalTextRows;
