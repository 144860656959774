import { TaskDependency } from "../../../types/ganttChart";
import { ReactComponent as DependencyIcon } from "../../../assets/icons/dependency.svg";
import chipStyles from "../../../styles/UI/chips.module.css";
import { useAppDispatch } from "../../../store/hooks";
import { setClickedTaskDependencies } from "../../../store/ganttChart";
import { setHighlightedTasks } from "../../../store/search";

const DependencyChip = ({
  dependencyArray,
  border,
}: {
  dependencyArray: TaskDependency[];
  border?: boolean;
}) => {
  const dispatch = useAppDispatch();
  return (
    <div
      className={chipStyles.chip}
      style={{
        backgroundColor: "var(--col-lavendar-dark)",
        border: border ? "1px solid white" : " none",
      }}
      onClick={(e) => {
        e.stopPropagation();
        // dispatch(setClickedTaskDependencies(dependencyArray));
        dispatch(
          setHighlightedTasks(
            dependencyArray.flatMap((d) => [
              d.idDependentTask,
              d.idPrerequisiteTask,
            ])
          )
        );
      }}
    >
      <DependencyIcon />
    </div>
  );
};

export default DependencyChip;
