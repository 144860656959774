import { useAppSelector } from "../../../store/hooks";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";

const VerticalBackgroundColumn = ({
  fill,
  xPosition,
  striped,
  width,
}: {
  fill?: string;
  xPosition: number;
  striped?: boolean;
  width?: number;
}) => {
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const { pixelsPerHour, timelineRange } = useAppSelector(
    (state) => state.timelineSlice
  );

  return (
    <>
      {striped && (
        <defs>
          <pattern
            id="pattern-grey-stripes"
            width="4"
            height="4"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <rect
              width="2"
              height="4"
              transform="translate(0,0)"
              fill="var(--col-grey)"
            ></rect>
          </pattern>
          <mask id="mask-stripe">
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#pattern-grey-stripes)"
            />
          </mask>
        </defs>
      )}
      {verticalColumnWidth && pixelsPerHour && timelineRange && (
        <rect
          style={{ mask: striped ? "url(#mask-stripe)" : "", fill: fill }}
          y="0"
          x={xPosition}
          width={width ? width : verticalColumnWidth}
          height={timelineRange?.fullDayHours * pixelsPerHour!}
        ></rect>
      )}
    </>
  );
};

export default VerticalBackgroundColumn;
