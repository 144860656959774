import { TaskDependency, Violation, WorkTask } from "../../types/ganttChart";
import labels from "../../utils/labels";
import drawerStyles from "../../styles/drawer.module.css";
import DrawerBlock from "./DrawerBlock";
import TaskDependencyBox from "./TaskDependencyBox";

const TaskDependenciesList = ({ task }: { task: WorkTask }) => {
  const primaryTask = task.taskDependencies.filter(
    (dependency) => dependency.isPrimaryTask
  );
  const secondaryTasks = task.taskDependencies.filter(
    (dependency) => !dependency.isPrimaryTask
  );

  const taskDependencyViolations: Violation[] = task.violations.filter(
    (violation) => violation.type === "task_dependencies"
  );

  const hasDependencyGotViolation = (dependency: TaskDependency): boolean => {
    return taskDependencyViolations.some((violation: Violation) => {
      {
        return (
          violation.idDependentTask === dependency.idDependentTask.toString() &&
          dependency.idPrerequisiteTask.toString() ===
            violation.idPrerequisiteTask
        );
      }
    });
  };
  return (
    <DrawerBlock title={labels.dependencies}>
      {primaryTask.length > 0 && (
        <>
          <h3 className={drawerStyles.modalSubTitle}>
            {labels.dependsOnTitle}
          </h3>
          {primaryTask.map((dependency, index) => (
            <TaskDependencyBox
              key={index}
              dependency={dependency}
              violation={hasDependencyGotViolation(dependency)}
            />
          ))}
        </>
      )}
      {secondaryTasks.length > 0 && (
        <>
          <h3 className={drawerStyles.modalSubTitle}>
            {labels.isDependencyOfTitle}
          </h3>
          {secondaryTasks.map((dependency, index) => (
            <TaskDependencyBox
              key={index}
              dependency={dependency}
              violation={hasDependencyGotViolation(dependency)}
            />
          ))}
        </>
      )}
    </DrawerBlock>
  );
};

export default TaskDependenciesList;
