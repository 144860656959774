import ganttChartService from "../../../service/ganttChartService";
import { useAppSelector } from "../../../store/hooks";
import { GanttChartData } from "../../../types/ganttChart";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalBackgroundColumn from "./VerticalBackgroundColumn";
import VerticalShiftColumn from "./VerticalShiftColumn";
import { Fragment } from "react";

const BackgroundColumns = ({
  ganttChartData,
  verticalColumnWidth,
}: {
  ganttChartData: GanttChartData;
  verticalColumnWidth: number;
}) => {
  //pixelsPerHour,
  const { pixelPerMinute, timelineRange } = useAppSelector(
    (state) => state.timelineSlice
  );
  const { unplannedColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  return (
    <>
      {/* Background for Unplanned column */}
      <VerticalBackgroundColumn
        xPosition={ganttChartVerticalValues.verticalViewHeaderColumn}
        fill="var(--col-grey-1)"
        key={"unplanned-column"}
        striped
        width={unplannedColumnWidth}
      />
      {/* Background for planned columns */}
      {ganttChartData.employees.map((_, index) => (
        <Fragment key={index}>
          <VerticalBackgroundColumn
            xPosition={
              ganttChartVerticalValues.verticalViewHeaderColumn +
              unplannedColumnWidth +
              index * verticalColumnWidth
            }
            fill="var(--col-grey-1)"
            key={"planned-column"}
            striped
          />
        </Fragment>
      ))}
      {ganttChartData.employees.map((employee, index) => (
        <Fragment key={index}>
          <VerticalShiftColumn
            yPosition={ganttChartVerticalValues.findTaskYPosition(
              timelineRange!,
              pixelPerMinute,
              employee.shiftStartTime
            )}
            xPosition={
              // TOdo: Tanja come back to
              // verticalColumnWidth >=
              // ganttChartVerticalValues.verticalViewMinColumnWidth
              //   ? ganttChartVerticalValues.verticalViewHeaderColumn +
              //     (index + 1) * verticalColumnWidth
              //   : ganttChartVerticalValues.verticalViewHeaderColumn +
              //     (index + 1) *
              //       ganttChartVerticalValues.verticalViewMinColumnWidth

              ganttChartVerticalValues.verticalViewHeaderColumn +
              unplannedColumnWidth +
              index * verticalColumnWidth
            }
            fill="white"
            key={"planned-column"}
            height={
              ganttChartService.timeDifferenceInMinutes(
                employee.shiftStartTime,
                employee.shiftEndTime
              ) * pixelPerMinute
            }
          />
        </Fragment>
      ))}
    </>
  );
};

export default BackgroundColumns;
