import { useEffect } from "react";
import buttonStyles from "../../../styles/UI/buttons.module.css";

const IconButtonComponent = ({
  icon,
  id,
  onClick,
  classes,
  disable,
}: {
  id: string;
  icon: React.ReactNode;
  onClick: () => void;
  classes?: string;
  disable?: boolean;
}) => {
  // useEffect to programaticcaly set the color of icon.
  useEffect(() => {
    const buttonElement = document.getElementById(id);
    const path = buttonElement?.querySelectorAll("path");
    if (path && path[0]) {
      path[0].setAttribute(
        "fill",
        disable ? "var(--col-black-60)" : "var(--col-black)"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable]);

  return (
    <button
      id={id}
      className={`${classes || null} ${buttonStyles.iconButton}`}
      onClick={onClick}
      disabled={disable}
    >
      {icon}
    </button>
  );
};

export default IconButtonComponent;
