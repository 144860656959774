import { useAppSelector } from "../../../store/hooks";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalGanttChartCenteredCell from "./VerticalGanttChartCenteredCell";
// import { useAppSelector } from "../../../store/hooks";

const VerticalTextRow = ({
  title,
  array,
}: {
  title: string;
  array?: React.ReactNode[];
}) => {
  const { verticalColumnWidth, unplannedColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  return (
    <div className={ganttChartStyles.verticalColumnTextItemsWrapper}>
      <p
        className={ganttChartStyles.columnTitleCell}
        style={{
          height:
            ganttChartVerticalValues.verticalViewTextRowHeight.toString() +
            "px",
        }}
      >
        {title}
      </p>
      {/* Unplanned cells. Shudl be left empty, as it holds no values */}
      <VerticalGanttChartCenteredCell
        fullOpacity
        key={`VerticalTextRow-0`}
        bgColor="var(--col-grey-1)"
        width={unplannedColumnWidth}
      >
        <p></p>
      </VerticalGanttChartCenteredCell>
      {array &&
        array.map((item, index) => (
          <VerticalGanttChartCenteredCell
            fullOpacity
            key={`VerticalTextRow-${index + 1}`}
            width={verticalColumnWidth}
          >
            {item}
          </VerticalGanttChartCenteredCell>
        ))}
    </div>
  );
};

export default VerticalTextRow;
