import { createSlice } from "@reduxjs/toolkit";
import { ActiveNexusFilter } from "../../types/ganttChart";
import {
  getActiveNexusFilters,
  getNexusFilters,
  getPlanningDates,
} from "./action";

type PlanningDate = {
  id: number;
  planningDate: string;
  scenarioId: number;
  nexusIdSolution?: number;
  pondooIdSolution?: number;
};

export interface NexusFilters {
  nexusFilters?: string[];
  fromDate?: string;
  toDate?: string;
  filtersString?: string;
  activeNexusFilters?: ActiveNexusFilter[];
  selectedActiveNexusFilter?: ActiveNexusFilter;
  selectedNexusFilterDate?: string;
  planningDates?: PlanningDate[];
  errorOnFilters?: string;
  errorOnActiveFilters?: string;
  errorOnPlanningDates?: string;
}

const initialState: NexusFilters = {};

const nexusFiltersSlice = createSlice({
  name: "gantt-chart",
  initialState,
  reducers: {
    setActiveNexusFilter(state, action) {
      state.selectedActiveNexusFilter = action.payload;
    },
    setSelectedNexusFilterDate(state, action) {
      state.selectedNexusFilterDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNexusFilters.fulfilled, (state, action) => {
      state.nexusFilters = action.payload.filters;
    });
    builder.addCase(getNexusFilters.pending, (state) => {
      delete state.nexusFilters;
    });
    // Todo: handle error
    builder.addCase(getNexusFilters.rejected, (state) => {
      state.errorOnFilters = "Failed to retrieve filters.";
    });
    builder.addCase(getActiveNexusFilters.fulfilled, (state, action) => {
      state.activeNexusFilters = action.payload.filters;
    });
    builder.addCase(getActiveNexusFilters.pending, (state) => {
      delete state.activeNexusFilters;
    });
    // Todo: handle error
    builder.addCase(getActiveNexusFilters.rejected, (state) => {
      state.errorOnActiveFilters = "Failed to retrieve filters.";
    });
    builder.addCase(getPlanningDates.fulfilled, (state, action) => {
      state.planningDates = action.payload.dates;
    });
    builder.addCase(getPlanningDates.pending, (state) => {
      delete state.planningDates;
    });
    // Todo: handle error
    builder.addCase(getPlanningDates.rejected, (state) => {
      state.errorOnPlanningDates =
        "Failed to retrieve available planning dates.";
    });
  },
});

export const { setActiveNexusFilter, setSelectedNexusFilterDate } =
  nexusFiltersSlice.actions;

export default nexusFiltersSlice.reducer;
