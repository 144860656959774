class labels {
  static adress = "Adresse";
  static citizens = "Borgere";
  static citizen = "Borger";
  static visit = "Besøg";
  static visitHours = "Besøgstid";
  static visitDetails = "Besøgdetaljer";
  static employees = "Medarbejdere";
  static employee = "Medarbejder";
  static hours = "Timer";
  static details = "Detaljer";
  static dependencies = "Afhængigheder";
  static task = "Ydelse";
  static tasks = "Ydelser";
  static priority = "Prioritet";
  static citizenDetails = "Borgerdetaljer";
  static skillRequirement = "Kompetencekrav";
  static skills = "Kompetencer";
  static time = "Tid";
  static expenses = "Omkostninger";
  static citizenService = "Borgerservice";
  static affinity = "Kontinuitet";
  static filter = "Filtre";
  static AIMissing = "AI planlægning mangler";
  static AICompleted = "AI planlægning er færdig";
  static search = "Søg";
  static choosePeriod = "Vælg periode";
  static locked = "Låst på medarbejder";
  static blocked = "Borger undtaget fra kontakt";
  static score = "Score";
  static preferrences = "Præferencer";
  static competencies = "kompetencer";
  static assignedEmployee = "Tildelt medarbejder";
  static preferences = "Præferencer";
  static high = "Høj";
  static normal = "Normal";
  static searchPrompt = "Søg borger eller ydelse";

  // Task Dependencies
  static isDependencyOfTitle = "Besøget har følgende tilknyttede besøg";
  static dependsOnTitle = "Besøget er tilknyttet følgende primære besøg";
  static startTimeColumnLabel = "Opstart";
  static endTimeColumnLabel = "Slut";

  // Violations
  static violationSkill =
    "Medarbejderen opfylder ikke alle kompetencekrav på opgaven";
  static violationDependency = "En tids sammenkædning er ikke overholdt";
  static violationDependencySameEmployee =
    "En samme medarbejder sammenkædning er ikke overholdt";
  static violationOverdue = "Det tilladte sluttidspunkt er ikke overholdt";
  static violationOvertime = "Medarbejderen har for mange timer";
  static violationTravelTotalDistance =
    "Medarbejderen har for lang transport over hele dagen";
  static violationTravelTripDistance =
    "Medarbejderen har for lang transport på en enkelt tur";
  static violationTravelFirstLastTripDistance =
    "Medarbejderen har for lang transport på første eller sidste tur";
  static violationDependencyUnplanned =
    "Opgaven kan ikke udføres, da en afhængig opgave er uplanlagt.";
  static violationOfTotalTravelDistance =
    "Den samlede distance, som medarbejderen har rejst, overstiger den tilladte grænse.";
  static violationOfTravelTripDistance =
    "Afstanden, som medarbejderen har rejst på en enkelt tur, overstiger den tilladte grænse.";
  static violationOfFirstLastTripDistance =
    "Afstanden, som medarbejderen har rejst på den første eller sidste tur, overstiger den tilladte grænse.";
  static vilationBlockedEmployee =
    "Borgeren er undtaget fra kontakt ved den planlagte medarbejder";

  // Violation messages
  static unspecifiedViolationMessage =
    "En uspecificeret regel er ikke blevet overholdt.";
  static dependencyMinOverlapViolationMessage = (minutes: string) =>
    `Besøgene skal overlappe med mindst ${minutes} minutter.`;
  static sameEmployeeViolationMessage =
    "Besøgene skal udføres af samme medarbejder.";
  static maxDelayTimeViolationMessage = (
    minutes: string,
    anchor: "START" | "END"
  ) =>
    `Det tilknyttede besøg skal starte højest ${minutes} minutter efter det primære besøg ${anchor === "END" ? "slutter" : "starter"}.`;
  static minDelayTimeViolationMessage = (
    minutes: string,
    anchor: "START" | "END"
  ) =>
    `Det tilknyttede besøg skal starte mindst ${minutes} minutter efter det primære besøg ${anchor === "END" ? "slutter" : "starter"}.`;
  static sameStartViolationMessage = "Besøgene skal starte samtidigt.";
  static startRightAfterViolationMessage =
    "Det tilknyttede besøg skal starte umiddelbart efter det primære besøg.";
  // buttons
  static sinceNexusData = "Siden Nexus-data";
  static sendNexusData = "Send plan til Nexus";
  static UpdatePlan = "Opdater planlægning";

  // login page
  static login = "Log ind";

  static hoverEmployee = "Medarbejder";
  static hoverTasks = "Ydelser";

  static pondooCompleted = (hours: number) => {
    return `Pondoo plan opdateret ${hours} ${hours > 1 ? "timer" : "time"} siden`;
  };
  static pondooNotUpdated = "Pondoo plan ikke opdateret";
  static pondooMissing = "Pondoo plan mangler";
  static pondooStatusError = "Pondoo plan fejlede";
  static pondooRunning = "Kører...";
  static tryAgain = "Prøv igen";
  static update = "Opdater";
}

export default labels;
