import { TaskDependency } from "../types/ganttChart";
import labels from "./labels";

export class DependencyMessageFactory {
  static getDependencyMessages = (dependency: TaskDependency): string[] => {
    // test if mapping to both dependent and prerequisite
    const messages: string[] = [];

    if (
      (dependency.maxDelayTime || dependency.maxDelayTime === 0) &&
      dependency.maxDelayAnchor
    )
      messages.push(
        labels.maxDelayTimeViolationMessage(
          dependency.maxDelayTime.toString(),
          dependency.maxDelayAnchor as "START" | "END"
        )
      );
    if (
      (dependency.minDelayTime || dependency.minDelayTime === 0) &&
      dependency.minDelayAnchor
    )
      messages.push(
        labels.minDelayTimeViolationMessage(
          dependency.minDelayTime.toString(),
          dependency.minDelayAnchor as "START" | "END"
        )
      );

    if (dependency.minOverlap) {
      messages.push(
        labels.dependencyMinOverlapViolationMessage(
          dependency.minOverlap.toString()
        )
      );
    }

    if (dependency.sameEmployee)
      messages.push(labels.sameEmployeeViolationMessage);

    if (dependency.startRightAfter)
      messages.push(labels.startRightAfterViolationMessage);

    if (dependency.sameStartTime)
      messages.push(labels.sameStartViolationMessage);

    return messages;
  };
}
