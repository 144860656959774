import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import TinyDivider from "../UI/TinyDivider";
import headerStyles from "../../styles/header.module.css";
import { useAppSelector } from "../../store/hooks";
import labels from "../../utils/labels";

const HeaderKPIList = () => {
  const { ganttChartData } = useAppSelector( //selectedGanttChartTab
    (state) => state.ganttChartSlice
  );

  return (
    <div className={headerStyles.kpiList}>
       (
        <>
          <div className={headerStyles.kpiList__group}>
            <>
              <GreyOpacityText
                key={labels.employees.toLowerCase()}
                size="SMALL"
              >
                {ganttChartData?.generalGanttChartInfo.kpis.employees}{" "}
                {labels.employees.toLowerCase()}
              </GreyOpacityText>
              <GreyOpacityText key={labels.hours.toLowerCase()} size="SMALL">
                {ganttChartData?.generalGanttChartInfo.kpis.employeeWorkHours}{" "}
                {labels.hours.toLowerCase()}
              </GreyOpacityText>
            </>
          </div>
          <TinyDivider colorVariant="PRIMARY"></TinyDivider>
          <div className={headerStyles.kpiList__group}>
            <>
              <GreyOpacityText key={labels.visit.toLowerCase()} size="SMALL">
                {ganttChartData?.generalGanttChartInfo.kpis.visits}{" "}
                {labels.visit.toLowerCase()}
              </GreyOpacityText>
              <GreyOpacityText
                key={`visit_${labels.hours.toLowerCase()}`}
                size="SMALL"
              >
                {ganttChartData?.generalGanttChartInfo.kpis.visitTotalHours}{" "}
                {labels.hours.toLowerCase()}
              </GreyOpacityText>
            </>
          </div>
        </>
      )
      {/* Todo: Is this needed?  */}
      {/* {selectedGanttChartTab === "Pondoo" && (
        <>
          <div className={headerStyles.kpiList__group}>
            <>
              <GreyOpacityText
                key={ganttChartData?.pondooSpecific?.kpis.employees}
                size="SMALL"
              >
                {ganttChartData?.pondooSpecific?.kpis.employees
                  ? ganttChartData?.pondooSpecific?.kpis.employees
                  : 0}
                {labels.employees.toLowerCase()}
              </GreyOpacityText>
              <GreyOpacityText
                key={ganttChartData?.pondooSpecific?.kpis.employeeWorkHours}
                size="SMALL"
              >
                {ganttChartData?.pondooSpecific?.kpis.employeeWorkHours
                  ? ganttChartData?.pondooSpecific?.kpis.employeeWorkHours
                  : 0}{" "}
                {labels.hours.toLowerCase()}
              </GreyOpacityText>
            </>
          </div>
          <TinyDivider colorVariant="PRIMARY"></TinyDivider>
          <div className={headerStyles.kpiList__group}>
            <>
              <GreyOpacityText
                key={ganttChartData?.pondooSpecific?.kpis.visits}
                size="SMALL"
              >
                {ganttChartData?.pondooSpecific?.kpis.visits
                  ? ganttChartData?.pondooSpecific?.kpis.visits
                  : 0}{" "}
                {labels.visit.toLowerCase()}
              </GreyOpacityText>
              <GreyOpacityText
                key={ganttChartData?.pondooSpecific?.kpis.visitTotalHours}
                size="SMALL"
              >
                {ganttChartData?.pondooSpecific?.kpis.visitTotalHours
                  ? ganttChartData?.pondooSpecific?.kpis.visitTotalHours
                  : 0}{" "}
                {labels.hours.toLowerCase()}
              </GreyOpacityText>
            </>
          </div>
        </>
      )} */}
    </div>
  );
};

export default HeaderKPIList;
