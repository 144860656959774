import {
  removeFilter,
  removeHighlightedTask,
  removeItemFromSearchHistory,
  SearchItem,
} from "../../store/search";
import { ReactComponent as BlackStars } from "../../assets/icons/black-stars.svg";
import { ReactComponent as Profile } from "../../assets/icons/Profile.svg";
import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import ColouredRoundWrapper from "../UI/ColouredRoundWrapper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const SearchItemChip = ({ searchResult }: { searchResult: SearchItem }) => {
  const dispatch = useAppDispatch();

  return (
    <ColouredRoundWrapper
      bgColor={"#F9F9F9"}
      height={"23px"}
      width={"fit-content"}
      classes="border rounded-full whitespace-nowrap px-2.5 cursor-pointer"
      onClick={() => {
        dispatch(removeHighlightedTask(searchResult.ids[0]));
        dispatch(removeItemFromSearchHistory(searchResult));
        dispatch(removeFilter(searchResult));
      }}
    >
      {searchResult.itemType === "TASK" ? (
        <BlackStars height={12} width={12} />
      ) : (
        <Profile height={12} width={12} />
      )}
      <GreyOpacityText size={"SMALL"}>{searchResult.string}</GreyOpacityText>
    </ColouredRoundWrapper>
  );
};

export default SearchItemChip;
